<template>
<div>
  <vx-card style="background-color: #000a12 ;">
    <vs-row vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
        <div><h5 style="color: white">PROPOSTAS DE RENOVAÇÃO: {{propostasRenovacao.length}}</h5></div>
      </vs-col>

    </vs-row>
  </vx-card>
  <VuePerfectScrollbar class="scroll-prosposta-renovacao-lista"  :settings="settings" style="background-color: #ebebeb;">
  <div id="proposta_renovacao" class="vs-con-loading__container">
    <div v-if="!propostasRenovacao.length > 0">
      <div class="con-colors mt-10 mb-20">
          <ul>
            <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important"><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon></span>
          </ul>
        </div>
      </div>
    </div>
    <vs-list
        v-for="(data, idx) in propostasRenovacao"
        v-bind:key="idx"
      >
      <propostaRenovacaoCard :item="data" @update="getPropostaRenovacao()">
        <template slot="action-buttons">
              <div class="flex flex-wrap">
              </div>
            </template>
      </propostaRenovacaoCard>
    </vs-list>
  </VuePerfectScrollbar>
</div>
</template>
<script>
import components from "@/components/default/exports.js";
import propostaRenovacaoCard from "./propostaRenovacaoCard.vue"


export default {
  props: {filtro: {require: true}},
  data() {
    return {
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed: 0.8
      },
      propostasRenovacao: {}
    };
  },
  methods: {
    async getPropostaRenovacao() {
      await this.$vs.loading({ container: '#proposta_renovacao',scale: 0.6});
      try {
        let id = localStorage.getItem('id')
        this.propostasRenovacao = await this.$http.get("getPropostaRenovacao/" + id)
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close('#proposta_renovacao > .con-vs-loading');
    }
  },
  components:{ ...components, propostaRenovacaoCard},
  async mounted(){
    await this.getPropostaRenovacao()
  }
  };
</script>

<style lang="scss" scoped>
.newCard {
    transition-duration: 50ms;
    background-color: #021a33 !important;
    color: white;
    &:hover {
        transition-duration: 150ms;
        transform: translateY(-1px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);
        background-color: #00BBA2 !important;
        color: white;
        cursor: pointer;

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
.scroll-prosposta-renovacao-lista {
  height: 70vh !important;
}
</style>
