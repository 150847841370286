import { render, staticRenderFns } from "./propostaRenovacaoLista.vue?vue&type=template&id=6fbb4bc7&scoped=true&"
import script from "./propostaRenovacaoLista.vue?vue&type=script&lang=js&"
export * from "./propostaRenovacaoLista.vue?vue&type=script&lang=js&"
import style0 from "./propostaRenovacaoLista.vue?vue&type=style&index=0&id=6fbb4bc7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbb4bc7",
  null
  
)

export default component.exports