<template>
  <div class="w-full py-0 my-0">
    <div>
      <vs-row
        vs-type="flex"
        class="mb-4"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
          <h4 style="color: #b4aa99">Para Renovação</h4>
        </vs-col>
      </vs-row>
    </div>
    <!-- <vs-row class="py-0 my-0"> -->
    <!-- <vs-col vs-w="4" class="px-1">
          <contratoVencendoLista></contratoVencendoLista>
      </vs-col>
      <vs-col vs-w="4" class="px-1">
        <propostaRenovacaoLista></propostaRenovacaoLista>
      </vs-col>
      <vs-col vs-w="4" class="px-1">
          <contratoRenovacaoLista></contratoRenovacaoLista>
    </vs-col>-->
    <!-- </vs-row> -->
    <div
      id="contrato_vencendo"
      class="cardTable scroll-kanban-renovacao mb-1 vs-con-loading__container"
    >
      <div v-if="contratosVencendo.length == 0">
        <div class="pt-20 con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Documento para Renovação
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1">
        <!-- <VuePerfectScrollbar class="scroll-kanban-renovacao" :settings="settings"> -->
        <vs-table
          pagination
          search
          maxHeight="62vh"
          max-items="9"
          class="mb-3 mx-2"
          stripe
          :data="contratosVencendo"
        >
          <template slot="thead">
            <vs-th sort-key="id" style="width: 5%">Número</vs-th>
            <vs-th sort-key="id_cliente" style="width: 25%">Cliente</vs-th>
            <vs-th sort-key="id_cliente" style="width: 20%">Consultor</vs-th>
            <vs-th style="width: 15%">Telefone</vs-th>
            <vs-th sort-key="id_status" style="width: 15%"
              >Estágio/Status</vs-th
            >
            <vs-th sort-key="valor" style="width: 10%" id="center">Valor</vs-th>
            <vs-th style="width: 20%" id="center">Vencimento</vs-th>
            <vs-th style="width: 20%" id="center">Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(tr, index) in data">
              <!-- <vs-td :data="tr.id" class="text-center">
                  <b style="color: darkblue; cursor: pointer">{{ tr.id }}</b>
                </vs-td>-->
              <vs-td :data="tr.id" class="text-center">
                <b>{{ tr.id }}</b>
              </vs-td>
              <vs-td :data="tr.id_cliente">
                <VisualizarCliente
                  @update="close()"
                  :id_cliente="tr.id_cliente"
                  :nomeCliente="tr.nome_fantasia"
                />
              </vs-td>
              <vs-td class="font-semibold" style="font-size: 12px">
                {{
                  tr.nomeColaborador != null
                    ? tr.nomeColaborador
                    : "Sem consultor"
                }}
              </vs-td>
              <vs-td>
                <span class="text-center">{{ tr.telefone }}</span>
              </vs-td>
              <vs-td>
                {{ tr.estagio }} {{ tr.tipo_faturamento }} -
                <span class="font-semibold">{{ tr.status }}</span>
              </vs-td>

              <vs-td>
                R${{ (tr.valor - tr.valor * (tr.desconto / 100)).toFixed(2) }}
              </vs-td>
              <vs-td>
                {{
                  tr.data_vencimento != null
                    ? tr.data_vencimento
                    : "Sem data" | moment("DD/MM/YYYY")
                }}
              </vs-td>
              <vs-td class="actions">
                <a :href="'/contrato?id=' + tr.id" target="_blank">
                  <vs-button
                    icon="remove_red_eye"
                    radius
                    type="relief"
                    size="small"
                    color="success"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import propostaRenovacaoLista from "./components/propostaRenovacaoLista.vue"
import VisualizarCliente from "./../cliente/visualizar.vue"
import contratoVencendoLista from "./components/contratoVencendoLista.vue"
import contratoRenovacaoLista from "./components/contratoRenovacaoLista.vue"
export default {
  data () {
    return {
      contratosVencendo: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async getContratoVencendo () {
      await this.$vs.loading({ container: "#contrato_vencendo", scale: 0.6 })
      try {
        let id = localStorage.getItem("id")
        this.contratosVencendo = await this.$http.post(
          "getContratoVencendo/" + id,
          { dataVencimento: await this.$formartData.somarMeses(new Date(), 1) }
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close("#contrato_vencendo > .con-vs-loading")
    },
    async verContrato (doc) {
      location.href = "/contrato?id=" + doc.id
    }
  },
  components: {
    ...components,
    contratoVencendoLista,
    propostaRenovacaoLista,
    contratoRenovacaoLista,
    VisualizarCliente
  },
  async mounted () {
    await this.getContratoVencendo()
  }
};
</script>

<style lang="scss">
.no-pad {
  padding: 0px !important;
}
.scroll-area-pros {
  height: 70vh !important;
}
.con-vs-chip {
  min-height: 15px;
  min-width: 20px !important;
  font-size: 0.8rem;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
.scroll-kanban-renovacao {
  height: 82vh !important;
}
</style>
