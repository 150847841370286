 <template>
  <div :id="'contrato_renov_card' + item.id" class="vs-con-loading__container">
    <vx-card :class="cardClass">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              v-if="item.nome_fantasia"
              class="pb-1"
              vs-w="10"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <ModalVisualizarCliente
                @update="getCliente()"
                :id_cliente="cliente.id"
                :nomeCliente="cliente.nome_fantasia"
                textColor="dark"
              />
            </vs-col>
            <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <!-- <Badge :text="'NOVO'" color="success"></Badge> -->
            </vs-col>
            <div @click="visualizar()">
              <vs-col
                v-if="item.telefone"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Telefone:
                  <span class="font-semibold">{{ item.telefone }}</span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.estagio"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  <span class="font-semibold"
                    >{{ item.estagio }} N°{{ item.id }} -
                    {{ item.status }}</span
                  >
                </span>
              </vs-col>
              <vs-col
                v-if="item.valor > 0"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Valor
                  <span class="font-semibold">
                    {{ $currency(item.valor) }}
                  </span>
                  <span class="item-description truncate text-sm">
                    - Valor da hora:
                    <span class="font-semibold">R$ 200,00</span>
                  </span>
                </span>
              </vs-col>
              <vs-col
                v-if="item.data_vencimento"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm">
                  Data de Vencimento:
                  <span class="font-semibold">{{
                    item.data_vencimento | moment("DD/MM/YYYY")
                  }}</span>
                </span>
              </vs-col>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
  </div>
</template>
<script>
import ModalVisualizarCliente from "./../../cliente/visualizar.vue"
import components from "@/components/default/exports.js"
import router from "./../../../../router.js"

export default {
  props: {
    item: {
      required: true,
    },
  },
  data () {
    return {
      cardClass: "contrato-renovacao-card overflow-hidden",
      cliente: {},
    }
  },
  mounted () {
    this.getCliente()
  },
  methods: {
    async getCliente () {
      await this.$vs.loading({
        container: "#contrato_renov_card" + this.item.id,
        scale: 0.6,
      })

      try {
        this.cliente = await this.$http.get(`cliente/` + this.item.id_cliente)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close(
        "#contrato_renov_card" + this.item.id + " > .con-vs-loading"
      )
    },
    async visualizar () {
      await router.push("/contrato?id=" + this.item.id)
    },
  },
  components: { ...components, ModalVisualizarCliente },
};
</script>

<style lang="scss" scoped>
.contrato-renovacao-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>

