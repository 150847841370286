<template>
<div>
  <vx-card card-background="success">
    <vs-row vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
        <div><h5 style="color: white">CONTRATOS DE RENOVAÇÃO: {{contratosRenovacao.length}}</h5></div>
      </vs-col>

    </vs-row>
  </vx-card>
  <VuePerfectScrollbar class="scroll-contrato-renovacao-lista"  :settings="settings" style="background-color: #ebebeb;">
    <div id="contrato_renovacao" class="vs-con-loading__container">
      <div v-if="!contratosRenovacao.length > 0">
      <div class="con-colors mt-10 mb-20">
          <ul>
            <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important"><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon></span>
          </ul>
        </div>
      </div>
    </div>
    <vs-list
        v-for="(data, idx) in contratosRenovacao"
        v-bind:key="idx"
      >
      <contratoRenovacaoCard :item="data" @update="getContratoRenovacao()">
        <template slot="action-buttons">
              <div class="flex flex-wrap">
              </div>
            </template>
      </contratoRenovacaoCard>
    </vs-list>

  </VuePerfectScrollbar>
</div>
</template>
<script>
import components from "@/components/default/exports.js";
import contratoRenovacaoCard from "./contratoRenovacaoCard.vue";

export default {
  props: {},
  data() {
    return {
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed: 0.8
      },
      contratosRenovacao: {}
    }
  },
  methods: {
    async getContratoRenovacao() {
        await this.$vs.loading({ container: '#contrato_renovacao',scale: 0.6});
        try {
          let id = localStorage.getItem('id')
          this.contratosRenovacao = await this.$http.get("getContratoRenovacao/"+id)
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        }
        await this.$vs.loading.close('#contrato_renovacao > .con-vs-loading');
    }
  },
  components:{ ...components, contratoRenovacaoCard},
  async mounted(){
    await this.getContratoRenovacao()
  }
  };
</script>

<style lang="scss" scoped>
.newCard {
    transition-duration: 50ms;
    background-color: #021a33 !important;
    color: white;
    &:hover {
        transition-duration: 150ms;
        transform: translateY(-1px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);
        background-color: #00BBA2 !important;
        color: white;
        cursor: pointer;

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
.scroll-contrato-renovacao-lista {
  height: 70vh !important;
}
</style>
