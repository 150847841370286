<template>
<div>
  <vx-card style="background-color: #f44336 ;">
    <vs-row vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
        <div><h5 style="color: white">CONTRATOS VENCENDO: {{contratosVencendo.length}}</h5></div>
      </vs-col>

    </vs-row>
  </vx-card>
  <VuePerfectScrollbar class="scroll-contrato-vencendo-lista"  :settings="settings" style="background-color: #ebebeb;">
  <div id="contrato_vencendo" class="vs-con-loading__container">
    <div v-if="!contratosVencendo.length > 0">
      <div class="con-colors mt-10 mb-20">
          <ul>
            <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important"><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon></span>
          </ul>
      </div>
    </div>
    <vs-list
        v-for="(data, idx) in contratosVencendo"
        v-bind:key="idx"
      >
      <contratoVencendoCard :item="data" @update="getContratoVencendo()">
        <template slot="action-buttons">
              <div class="flex flex-wrap">
              </div>
            </template>
      </contratoVencendoCard>
    </vs-list>
  </div>


  </VuePerfectScrollbar>
</div>
</template>
<script>
import components from "@/components/default/exports.js";
import contratoVencendoCard from "./contratoVencendoCard";

export default {
  props: {},
  data() {
    return {
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed: 0.8
      },
      contratosVencendo: {}
    };
  },
  methods: {
    async getContratoVencendo() {
      await this.$vs.loading({ container: '#contrato_vencendo',scale: 0.6});
      try {
        let id = localStorage.getItem('id')
        this.contratosVencendo = await this.$http.post("getContratoVencendo/" + id, {dataVencimento: await this.$formartData.somarDias(new Date(), 30)})
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close('#contrato_vencendo > .con-vs-loading');
    }
  },
  components:{ ...components, contratoVencendoCard},
  async mounted(){
    await this.getContratoVencendo()
  }
  };
</script>

<style lang="scss" scoped>
.newCard {
    transition-duration: 50ms;
    background-color: #021a33 !important;
    color: white;
    &:hover {
        transition-duration: 150ms;
        transform: translateY(-1px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);
        background-color: #00BBA2 !important;
        color: white;
        cursor: pointer;

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
.scroll-contrato-vencendo-lista {
  height: 70vh !important;
}
</style>
